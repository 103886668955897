import * as React from "react";
import { CardColumns, Col, Row } from "reactstrap";
import { FenceSegment } from "models/fenceSegment";
import { FenceSegmentCard } from "./FenceSegmentCards/FenceSegmentCard";
import { FenceSegmentCardInProgress } from "./FenceSegmentCards/FenceSegmentCardInProgress";
import { FenceSegmentCardIncomplete } from "./FenceSegmentCards/FenceSegmentCardIncomplete";
import { GateSizeEnum } from "models/fenceGate";
import { EstimatePageCurrentMode } from "pages/EstimatePage";

type EstimateSideBarProps = {
  onAddGateDropDownSelect: (fenceSegmentId: string, size: GateSizeEnum) => React.MouseEventHandler<HTMLElement>;
  onEditStyleBtnClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDeleteBtnClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onGateDeleteBtnClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancelDrawingInProgress: (e: React.MouseEvent<HTMLButtonElement>) => void;
  fenceSegments?: FenceSegment[];
  //children: JSX.Element[] | JSX.Element;
  selectedFenceSegmentId: string;
  currentMode: EstimatePageCurrentMode;
};
function EstimateSidebar(props: EstimateSideBarProps) {
  console.info("load -- sidebar--");
  console.info(props.fenceSegments);
  if (!props.fenceSegments) return <></>;

  const isFenceDrawingModeEnabled = props.currentMode === EstimatePageCurrentMode.DrawingFence;
  return (
    <>
      <Row>
        <Col>
          <CardColumns>
            {props.fenceSegments.map((f, i) => {
              if (f.isIncomplete()) {
                return (
                  <FenceSegmentCardIncomplete
                    key={i}
                    fenceLinearFeet={f.calculateLinearFeet()}
                    fenceSegmentId={f.id}
                    handleDeleteStyle={props.onDeleteBtnClick}
                    handleAddFenceStyle={props.onEditStyleBtnClick}
                  />
                );
              } else {
                return (
                  <FenceSegmentCard
                    key={i}
                    fenceSegment={f}
                    handleEditStyle={props.onEditStyleBtnClick}
                    handleDeleteStyle={props.onDeleteBtnClick}
                    handleAddGate={props.onAddGateDropDownSelect}
                    handleGateDelete={props.onGateDeleteBtnClick}
                    handleCancelGateAdd={props.onCancelDrawingInProgress}
                    isSelected={props.selectedFenceSegmentId === f.id}
                    currentPageMode={props.currentMode}
                  />
                );
              }
            })}
            {isFenceDrawingModeEnabled && (
              <FenceSegmentCardInProgress handleCancelDrawingInProgress={props.onCancelDrawingInProgress} />
            )}
          </CardColumns>
        </Col>
      </Row>
    </>
  );
}

export default EstimateSidebar;
