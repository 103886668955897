export type Address = {
  street: string;
  city: string;
  state: string;
  zipCode: string;
  lat: number;
  lon: number;
  formattedAddress: string;
};

export function createAddressFromPlacesResult(place: google.maps.places.PlaceResult): Address | undefined {
  const findAddressComponent = (
    components: google.maps.GeocoderAddressComponent[] | undefined,
    componentType: string
  ) => {
    const emptyResult = undefined;
    if (components === undefined) return emptyResult;

    for (let i = 0; i < components.length; i++) {
      const element = components[i];
      if (element.types.includes(componentType)) return element;
    }
    return emptyResult;
  };

  const streetNumber = findAddressComponent(place.address_components, "street_number");
  // no valid place is selected
  if (streetNumber === undefined && place.geometry === undefined) return undefined;

  const streetName = findAddressComponent(place.address_components, "route")?.short_name || "";

  const addr: Address = {
    lat: place.geometry?.location?.lat() || 0,
    lon: place.geometry?.location?.lng() || 0,
    street: `${streetNumber!.long_name} ${streetName}`,
    city: findAddressComponent(place.address_components, "locality")?.long_name || "",
    state: findAddressComponent(place.address_components, "administrative_area_level_1")?.short_name || "",
    zipCode: findAddressComponent(place.address_components, "postal_code")?.short_name || "",
    formattedAddress: place.formatted_address || "",
  };

  return addr;
}
