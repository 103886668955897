import * as React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FenceSegment } from "models/fenceSegment";
import { Button, Col, Row, Spinner } from "reactstrap";
import { EstimateApi } from "api/api";
import { GetContactInformationModal } from "../GetContactInformationModal";
import { ContactInformation } from "models/contact";

type EstimateTotalProps = {
  fences: FenceSegment[];
  isContactInformationProvided: boolean;
  onContactInformationProvided: (contact: ContactInformation) => void;
  onGetFullQuoteClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const EstimateTotal = function (props: EstimateTotalProps) {
  const [priceRange, setPriceRange] = React.useState<{ min: number; max: number }>();
  const [openContactInfoModal, toggleContactInfoModalDislay] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const { estimateId } = useParams();

  React.useEffect(() => {
    if (props.fences.length === 0 || props.fences.every((f) => f.isIncomplete())) {
      return;
    }
    setLoading(true);
    EstimateApi.calculateEstimatePrice(estimateId!, props.fences)
      .then((data) => {
        setPriceRange({ max: data.max, min: data.min });
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Error calculating prices");
      });
  }, [setPriceRange, props.fences, estimateId]);

  const contactInformationSubmittedHandler = (contact: ContactInformation) => {
    props.onContactInformationProvided(contact);
    toggleContactInfoModalDislay(false);
  };
  const handleShowPriceBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.fences.some((f) => f.isIncomplete())) {
      toast.error("Please add missing style or delete the incomplete fence segment");
    } else {
      toggleContactInfoModalDislay(!openContactInfoModal);
    }
  };
  const handleGetFullQuoteBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.fences.some((f) => f.isIncomplete())) {
      toast.error("Please add missing style or delete the incomplete fence segment");
    } else {
      props.onGetFullQuoteClick(e);
    }
  };
  if (props.fences.every((f) => f.isIncomplete())) return <></>;

  return (
    <>
      {openContactInfoModal && (
        <GetContactInformationModal
          onClose={() => {
            toggleContactInfoModalDislay(false);
          }}
          onSubmit={contactInformationSubmittedHandler}
        />
      )}
      <Row style={{ paddingTop: "15px", paddingBottom: "15px" }}>
        <Col className="d-flex align-items-center justify-content-center">
          <h3>
            <EstimatePriceRow
              isObfuscated={!props.isContactInformationProvided}
              min={priceRange?.min || 0}
              max={priceRange?.max || 0}
              isLoading={isLoading}
            />
          </h3>
        </Col>
      </Row>
      <Row style={{ paddingBottom: "15px" }}>
        <Col className=" d-flex align-items-center justify-content-center">
          {!props.isContactInformationProvided && (
            <Button color="success" size="lg" onClick={handleShowPriceBtnClick}>
              Show Price
            </Button>
          )}
          {props.isContactInformationProvided && (
            <Button color="success" onClick={handleGetFullQuoteBtnClick}>
              Get Full Quote
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

type EstimatePriceRowProps = {
  isObfuscated: boolean;
  min: number;
  max: number;
  isLoading: boolean;
};
function EstimatePriceRow(props: EstimatePriceRowProps) {
  let min = formatter.format(props.min);
  let max = formatter.format(props.max);

  if (props.isObfuscated) {
    max = max.replace(/[0-9]/g, "X");
    min = min.replace(/[0-9]/g, "X");
  }

  if (props.isLoading) return <Spinner />;
  return (
    <>
      Total: {min} - {max}
    </>
  );
}
