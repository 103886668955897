import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

//if (process.env.NODE_ENV !== "development") {
//  ReactGA.initialize("G-2BKFCRJY64");
//}

root.render(
  //<React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
