import * as React from "react";
import { FormValues, IValidationRules, ValidationError, SelectOption } from "./contracts";
import { Card, CardBody } from "reactstrap";

export const haveErrors = <Keys extends string>(errors: FormValues<Keys>) => {
  let haveError: boolean = false;
  Object.keys(errors).forEach((key: string) => {
    if (!errors[key as Keys].isValid) {
      haveError = true;
      return;
    }
  });

  return haveError;
};

export const validateFormValues = <Keys extends string>(values: FormValues<Keys>) => {
  for (let name in values) {
    const formControl = values[name];
    var validationResult = isFieldValid(formControl.label, formControl.value, formControl.validationRules, true);
    formControl.isValid = validationResult.isValid;
    formControl.validationError = validationResult.errorMsg;
    formControl.touched = true;
  }
};

export const getValidationMessages = <Keys extends string>(values: FormValues<Keys>) => {
  const messages: string[] = [];
  for (let name in values) {
    const formControl = values[name];
    if (!formControl.isValid) {
      messages.push(formControl.validationError);
    }
  }
  return messages;
};

export const isFieldValid = (ctlName: string, value: any, rules: IValidationRules, isFormSubmit: boolean) => {
  let isValid: ValidationError = { isValid: true, errorMsg: "" };
  for (let rule in rules) {
    switch (rule) {
      case "maxLength":
        isValid = maxLengthValidator(ctlName, value, rules[rule]);
        break;
      case "isRequired":
        isValid = requiredFieldValidator(ctlName, value);
        break;
      case "isSelectionRequired":
        isValid = requiredSelectionValidator(ctlName, value);
        break;
      case "isEmail":
        isValid = isFormSubmit ? isEmail(ctlName, value) : isValid;
        break;
      case "isStrongPassword":
        isValid = isFormSubmit ? isStrongPassword(ctlName, value) : isValid;
        break;
      case "isUrl":
        isValid = isFormSubmit ? isUrl(ctlName, value) : isValid;
        break;
      case "isPhone":
        isValid = isFormSubmit ? isPhone(ctlName, value) : isValid;
        break;
      case "minValue":
        isValid = minValue(ctlName, value, rules.minValue as number);
        break;
      default:
        break;
    }
    if (!isValid.isValid) {
      break;
    }
  }

  return isValid;
};

const minValue = (ctlName: string, value: number = 0, threshold: number | undefined) => {
  if (threshold === undefined) {
    throw new Error("wrong valadation constraint type. should be number");
  }

  return {
    isValid: value > threshold,
    errorMsg: `Entered value must be greater than ${threshold}`,
  };
};

const isPhone = (ctlName: string, value: string) => {
  const expr = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  const regexp = new RegExp(expr);
  return {
    isValid: regexp.test(value),
    errorMsg: "Incorrect phone number format. Please use xxx-xxx-xxxx",
  };
};
const isUrl = (ctlName: string, value: string) => {
  //https://www.regextester.com/93652
  const expr =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gi;
  const regexp = new RegExp(expr);
  return {
    isValid: regexp.test(value),
    errorMsg: "Incorrect website format. Please use http://www.example.com",
  };
};

const isStrongPassword = (ctlName: string, value: string) => {
  let result = false;
  const numOfCapLetters = value.replace(/[^A-Z]/g, "").length;
  if (value.length >= 8 && numOfCapLetters > 0) {
    result = true;
  }
  return {
    isValid: result,
    errorMsg: "Password must be at least 8 characters with at least one capital letter",
  };
};
const isEmail = (ctlName: string, value: string) => {
  const regexp = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return {
    isValid: regexp.test(value),
    errorMsg: "Invalid email format",
  };
};
const maxLengthValidator = (ctlName: string, value: string, maxLength: number | boolean) => {
  if (typeof maxLength === "boolean") {
    // eslint-disable-next-line no-throw-literal
    throw "Boolean type not allowed on length validators";
  }

  return {
    isValid: value.trim().length <= maxLength,
    errorMsg: `${ctlName} has too many characters`,
  };
};
const requiredFieldValidator = (ctlName: string, value: string | boolean) => {
  return {
    isValid: value.toString().trim() !== "" && value.toString() !== "false",
    errorMsg: `${ctlName} field is required`,
  };
};
const requiredSelectionValidator = (ctlName: string, value: SelectOption | SelectOption[] | undefined) => {
  const errorResult = {
    isValid: false,
    errorMsg: `${ctlName} selection is required.`,
  };
  if (!value || (Array.isArray(value) && value.length === 0)) {
    return errorResult;
  }

  return {
    isValid: true,
    errorMsg: `${ctlName} selection is required.`,
  };
};

type Props = {
  validationErrors: string[];
};
export const ValidationSummary = function (props: Props) {
  if (props.validationErrors.length === 0) {
    return null;
  }
  return (
    <>
      <Card>
        <CardBody style={{ backgroundColor: "#ef8157" }}>
          <div>
            <ul style={{ paddingLeft: "5px" }}>
              {props.validationErrors.map((e, i) => (
                <li key={i}> {e} </li>
              ))}
            </ul>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
