import * as React from "react";
import { DrawingManager } from "@react-google-maps/api";

type GoogleDrawingManagerWrapperProps = {
  onLoadDrawingManager: (drawingManager: google.maps.drawing.DrawingManager) => void;
  onPolylineComplete?: (p: google.maps.Polyline) => void; // desktop
};
export function GoogleDrawingManagerWrapper(props: GoogleDrawingManagerWrapperProps) {
  return (
    <DrawingManager
      onLoad={props.onLoadDrawingManager}
      drawingMode={google.maps.drawing.OverlayType.POLYLINE}
      onPolylineComplete={props.onPolylineComplete}
      options={{
        drawingControl: false,
        drawingControlOptions: {
          drawingModes: [google.maps.drawing.OverlayType.POLYLINE],
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        polylineOptions: {
          clickable: true,
          editable: false,
          strokeColor: "#00ffff",
          strokeWeight: 6,
          zIndex: 1,
        },
      }}
    />
  );
}
