import * as React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import NotFoundPage from "pages/NotFoundPage";

type Props = {
  children: JSX.Element[] | JSX.Element;
};

export default function Layout(props: Props) {
  const { portalId } = useParams();
  if (portalId === undefined) return <NotFoundPage />;

  return (
    <>
      <Header portalId={portalId} />
      <main>{props.children}</main>
      <Footer />
    </>
  );
}
