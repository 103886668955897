import { createBrowserRouter, redirect } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import SelectLocationPage from "./pages/SelectLocationPage";
import EstimatePage from "./pages/EstimatePage";
import { ThankYouPage } from "./pages/ThankYouPage";
import Layout from "./layouts/Layout";
import NotFoundPage from "pages/NotFoundPage";
import ReactGA from "react-ga4";

function ExternalRedirect() {
  /*if (process.env.NODE_ENV !== "development") {
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Root",
    });
  }*/
  window.location.href = "https://www.fencebuildr.com";
  return null;
}
const router = createBrowserRouter([
  {
    path: "/",
    element: <ExternalRedirect />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/:portalId/designer",
    element: (
      <Layout>
        <SelectLocationPage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/:portalId/estimate/:estimateId",
    element: (
      <Layout>
        <EstimatePage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/:portalId/thank-you/:id",
    element: (
      <Layout>
        <ThankYouPage />
      </Layout>
    ),
  },
  {
    path: "/404",
    element: <NotFoundPage />,
  },
  {
    path: "/:portalId",
    loader: async () => {
      return redirect("designer");
    },
  },
]);

export default router;
