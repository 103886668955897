import * as React from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import "./estimate-card.css";

export type FenceSegmentCardInProgressProps = {
  handleCancelDrawingInProgress: (e: React.MouseEvent<HTMLButtonElement>) => void;
};
export function FenceSegmentCardInProgress(props: FenceSegmentCardInProgressProps) {
  return (
    <Card className="estimate-card-spacing-bottom">
      <CardHeader style={{ height: "38px" }} />
      <CardBody className="d-flex aligns-items-center justify-content-center">
        <Button color="danger" onClick={props.handleCancelDrawingInProgress}>
          Cancel Drawing
        </Button>
      </CardBody>
    </Card>
  );
}
