import * as React from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardText, Col, Row } from "reactstrap";
import { EstimateApi, GetEstimateGetResponse } from "api/api";
import "./thankyou.css";

export function ThankYouPage() {
  const { id } = useParams();

  const intialState: GetEstimateGetResponse = {
    contractor: {
      name: "",
      phone: "",
      email: "",
      address: { street: "", city: "", state: "", zipcode: "" },
    },
    contactInformation: {
      name: "",
      phone: "",
      preferredContactMethod: "",
      email: "",
    },
    worksite: {
      street: "",
      city: "",
      state: "",
      zipcode: "",
    },
    submittedEstimate: {
      id: "",
      friendlyId: "XXXXX",
      totalPriceLow: 0,
      totalPriceHigh: 0,
      segments: [{ description: "", gateDescriptions: [], linearFeet: 0 }],
    },
  };
  const [estimate, setEstimate] = React.useState<GetEstimateGetResponse>(intialState);

  React.useEffect(() => {
    EstimateApi.getEstimate(id!).then((d) => {
      setEstimate(d);
    });
  }, [id, setEstimate]);

  return (
    <>
      <div className="row g-lg-3 py-5 padding-hero">
        <div className="col-lg-7 text-center text-lg-start">
          <h1 className="display-4 fw-bold lh-1 mb-3">Thank you</h1>
          <p className="col-lg-10 fs-4">We will contact you to schedule a free onsite visit.</p>
        </div>
        <div className="col-md-10 mx-auto col-lg-5">
          {/* <p>
            <strong>Estimate ID:</strong> {estimate.submittedEstimate.friendlyId}
          </p>*/}
          <Row>
            <Col>
              <Card className="estimate-card-spacing-bottom">
                <CardBody>
                  <strong>Worksite</strong>

                  <address>
                    {estimate.worksite.street}
                    <br />
                    {estimate.worksite.city}, {estimate.worksite.state}, {estimate.worksite.zipcode}
                    <br />
                  </address>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="estimate-card-spacing-bottom">
                <CardBody>
                  <strong>Contact Information</strong>
                  <CardText>
                    {estimate.contactInformation.name}
                    <br />
                    {estimate.contactInformation.phone}
                    <br />
                    {estimate.contactInformation.email}
                    <br />
                    Method of contact: {estimate.contactInformation.preferredContactMethod}
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="estimate-card-spacing-bottom">
                <CardBody>
                  <strong>Details</strong>

                  <ul>
                    {estimate.submittedEstimate.segments.map((x, index) => {
                      return (
                        <>
                          <li key={index}>
                            {x.linearFeet.toLocaleString("en-us")} LFT {x.description}
                            {x.gateDescriptions.length > 0 && (
                              <ul>
                                {x.gateDescriptions.map((g, idx) => {
                                  return <li key={idx}>{g}</li>;
                                })}
                              </ul>
                            )}
                          </li>
                        </>
                      );
                    })}
                  </ul>

                  <strong>
                    Total: ${estimate.submittedEstimate.totalPriceLow.toLocaleString("en-us")} - $
                    {estimate.submittedEstimate.totalPriceHigh.toLocaleString("en-us")}
                  </strong>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
