import * as React from "react";
import { Navbar } from "reactstrap";
import { ReactComponent as Logo } from "./../icons/fence-svgrepo-com.svg";
import { EstimateApi } from "../api/api";
import { SessionStorageWrapper } from "../models/session-storage-wapper";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

type CompanyInfo = {
  name: string;
  street: string;
  city: string;
  zipcode: string;
  state: string;
  phone: string;
  email: string;
};

type HeaderProps = {
  portalId: string;
};
export default function Header(props: HeaderProps) {
  const [companyInfo, setCompanyInfo] = React.useState<CompanyInfo>();
  const navigate = useNavigate();

  React.useEffect(() => {
    EstimateApi.getCompanyInfo(props.portalId)
      .then((d) => {
        setCompanyInfo({
          name: d.name,
          street: d.street,
          city: d.city,
          state: d.state,
          zipcode: d.zipcode,
          phone: d.phone,
          email: d.email,
        });
        SessionStorageWrapper.addItem<string>("companyId", d.companyId);
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 404) {
          navigate("/404");
        }
        setCompanyInfo({
          name: "FenceBuildr",
          street: "d.street",
          city: "d.city",
          state: "d.state",
          zipcode: "d.zipcode",
          phone: "d.phone",
          email: "d.email",
        });
      });
  }, [setCompanyInfo, navigate, props.portalId]);


  const rootUrl = `/${props.portalId}/designer`;
  const mailto = `mailto:${companyInfo?.email}`;
  return (
    <header className="bg-light">
      <Navbar>
        <a href={rootUrl} className="navbar-brand d-flex align-items-center">
          <Logo width="30" height="24" className="d-inline-block align-text-top" /> <strong>{companyInfo?.name}</strong>
        </a>
        <div className="d-flex align-items-left">
          <address style={{ fontSize: ".7em" }}>
            {companyInfo?.street} <br />
            {companyInfo?.city}, {companyInfo?.state} {companyInfo?.zipcode} <br />
            {companyInfo?.phone}
            <br />
            <a href={mailto}>{companyInfo?.email}</a>
          </address>
        </div>
      </Navbar>
    </header>
  );
}
