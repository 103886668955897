import { LatLon } from "./fenceSegment";

export class FenceGate {
  id!: string;
  coords!: LatLon;
  size!: GateSizeEnum;
  constructor() {
    this.id = Math.random().toString(36).substring(2, 8); //https://www.newline.co/books/beginners-guide-to-typescript/generating-unique-ids
  }
}

export enum GateSizeEnum {
  SINGLE,
  DOUBLE,
}
