import * as React from "react";
import { Container } from "reactstrap";

export default function Footer() {
  return (
    <footer className="text-muted bg-light">
      <Container fluid>
        <div className="text-center p-4">
          © 2023{" "}
          <a className="text-reset fw-bold" href="https://www.fencebuildr.com/">
            Fencebuildr.com
          </a>
        </div>
      </Container>
    </footer>
  );
}
