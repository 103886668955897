/*global google */
import { Marker } from "@react-google-maps/api";
import { FenceGate, GateSizeEnum } from "models/fenceGate";
import { FenceSegment } from "models/fenceSegment";
import doubleGateIcon from "icons/double_gate.svg";
import singleGateIcon from "icons/single_gate.svg";

type Props = {
  fences: FenceSegment[];
};

export const GateMarkers = function (props: Props) {
  const gates: Array<FenceGate> = [];
  props.fences.forEach((f) => gates.push(...f.gates));

  return (
    <div>
      {gates.map((g) => {
        const icon = { url: singleGateIcon, anchor: new google.maps.Point(9, 9) };
        const position = new google.maps.LatLng(g.coords.lat, g.coords.lon);
        if (g.size === GateSizeEnum.DOUBLE) {
          icon.url = doubleGateIcon;
        }
        return <Marker key={g.id} position={position} icon={icon} />;
      })}
    </div>
  );
};
