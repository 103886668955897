/* global google */
import { Polyline } from "@react-google-maps/api";
import { FenceSegment } from "models/fenceSegment";

// dashed line
/*const lineSymbol = {
    path: 'M 0,-1 0,1',
    scale: 4,
    strokeOpacity: 1,
};*/

const selectedLineColor = "#2af713";
const otherLineColor = "#ffae00"; // 'blue'

type FenceLineProps = {
  paths: google.maps.LatLng[];
  fenceId: string;
  clickFunc: (fenceId: string) => (e: google.maps.PolyMouseEvent) => void;
  isSelected: boolean;
};

const FenceLine = function (props: FenceLineProps) {
  return (
    <Polyline
      key={props.fenceId}
      path={props.paths}
      options={{
        strokeColor: props.isSelected ? selectedLineColor : otherLineColor,
        strokeWeight: 6,
      }}
      onClick={props.clickFunc(props.fenceId)}
    />
  );
};

type Props = {
  //drawingMode: DrawingMode;
  selectedFenceId: string;
  fences: ReadonlyArray<FenceSegment>;
  onClick: (fenceId: string) => (e: google.maps.PolyMouseEvent) => void;
  //AddDoubleGateClickHandler: (fenceId: string) => (e: google.maps.PolyMouseEvent) => void;
  //AddSingleClickHandler: (fenceId: string) => (e: google.maps.PolyMouseEvent) => void;
};

export const FenceOutline = function (props: Props) {
  let clickFunc = props.onClick;
  /*if (props.drawingMode === "DRAWING_DOUBLE_GATE") {
    clickFunc = props.AddDoubleGateClickHandler;
  } else if (props.drawingMode === "DRAWING_SINGLE_GATE") {
    clickFunc = props.AddSingleClickHandler;
  }*/
  return (
    <div>
      {props.fences.map((f) => {
        return (
          <FenceLine
            key={f.id}
            paths={f.getCoordsAsGMapLatLng()}
            fenceId={f.id}
            clickFunc={clickFunc}
            isSelected={f.id === props.selectedFenceId}
          />
        );
      })}
    </div>
  );
};
