import axios, { AxiosResponse } from "axios";
import { AttributeNode } from "../models/fenceAttribute";
import { FenceSegment } from "../models/fenceSegment";
import { GateSizeEnum } from "../models/fenceGate";
import { Address } from "../models/address";
import { ContactInformation } from "../models/contact";
import { SessionStorageWrapper } from "models/session-storage-wapper";

console.log(process.env.REACT_APP_FENCE_API_SERVICE_URL);
const instance = axios.create({
  baseURL: process.env.REACT_APP_FENCE_API_SERVICE_URL,
  timeout: 25000,
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) => instance.get(url).then(responseBody),
  post: (url: string, body: {}) => instance.post(url, body).then(responseBody),
  put: (url: string, body: {}) => instance.put(url, body).then(responseBody),
  delete: (url: string) => instance.delete(url).then(responseBody),
};

interface GetFenceAttributesResponse {
  attributes: AttributeNode[];
}

interface SegmentEstimateRequest {
  linearFeet: number;
  materialId: string;
  styleId: string;
  colorId: string;
  heightId: string;
  singleGateCount: number;
  doubleGateCount: number;
}
interface CalculateEstimatePriceRequest {
  segments: Array<SegmentEstimateRequest>;
}

export interface CalculateEstimatePriceResponse {
  min: number;
  max: number;
}

export interface CreateEstimateResponse {
  estimateId: string;
}

export interface CompanyInformationGetResponse {
  companyId: string;
  name: string;
  street: string;
  city: string;
  zipcode: string;
  state: string;
  phone: string;
  email: string;
}

export interface GetEstimateGetResponse {
  contractor: {
    name: string;
    address: { street: string; city: string; state: string; zipcode: string };
    phone: string;
    email: string;
  };
  worksite: {
    street: string;
    city: string;
    state: string;
    zipcode: string;
  };
  contactInformation: {
    name: string;
    phone: string;
    preferredContactMethod: string;
    email: string;
  };
  submittedEstimate: {
    id: string;
    friendlyId: string;
    totalPriceLow: number;
    totalPriceHigh: number;
    segments: { description: string; gateDescriptions: string[]; linearFeet: number }[];
  };
}

export enum EstimateAction {
  RemoveGate = "RemovedGate",
  AddGate = "AddedGate",
  AddSegment = "AddedSegment",
  RemoveSegment = "RemoveSegment",
  AddContactInfo = "AddedContactInfo",
  ReguestQuote = "RequestedQuote",
}

export const EstimateApi = {
  getFenceAttributes: (cId: string): Promise<GetFenceAttributesResponse> =>
    requests.get(`api/v1/designer/fence-catalog/${cId}`),
  calculateEstimatePrice: (estimateId: string, data: FenceSegment[]): Promise<CalculateEstimatePriceResponse> => {
    const estimateRequest: CalculateEstimatePriceRequest = {
      segments: [],
    };
    data.forEach((f) => {
      estimateRequest.segments.push({
        linearFeet: f.calculateLinearFeet(),
        materialId: f.material.id!,
        styleId: f.style.id!,
        colorId: f.color.id!,
        heightId: f.height.id!,
        singleGateCount: f.gates.filter((g) => g.size === GateSizeEnum.SINGLE).length,
        doubleGateCount: f.gates.filter((g) => g.size === GateSizeEnum.DOUBLE).length,
      });
    });
    return requests.post(`api/v1/designer/estimate/${estimateId}/price`, estimateRequest);
  },
  createEstimate: (address: Address): Promise<CreateEstimateResponse> => {
    const data = {
      workSite: address,
      contactInformation: undefined,
    };
    const cId = SessionStorageWrapper.getItem("companyId");
    return requests.post(`api/v1/designer/estimate/${cId}`, data);
  },
  saveContactInformation: (estimateId: string, contact: ContactInformation): Promise<void> =>
    requests.post(`api/v1/designer/estimate/${estimateId}/contact`, contact),

  saveEstimate: (estimateId: string, segments: FenceSegment[], action: EstimateAction): Promise<void> => {
    const data = {
      segments: segments,
      action: action,
    };
    return requests.put(`api/v1/designer/estimate/${estimateId}`, data);
  },
  getCompanyInfo: (portalId: string): Promise<CompanyInformationGetResponse> => {
    return requests.get(`api/v1/designer/company/${portalId}`);
  },
  getEstimate: (id: string): Promise<GetEstimateGetResponse> => {
    return requests.get(`api/v1/designer/estimate/${id}`);
  },
};
