import * as React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import { FenceSegment } from "models/fenceSegment";
import { FenceGate, GateSizeEnum } from "models/fenceGate";
import { EstimatePageCurrentMode } from "pages/EstimatePage";

type FenceSegmentCardProps = {
  handleGateDelete: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleAddGate: (fenceSegmentId: string, size: GateSizeEnum) => React.MouseEventHandler<HTMLElement>;
  handleEditStyle: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleDeleteStyle: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleCancelGateAdd: (e: React.MouseEvent<HTMLButtonElement>) => void;

  fenceSegment: FenceSegment;
  isSelected: boolean;
  currentPageMode: EstimatePageCurrentMode;
};

function FenceSegmentCard(props: FenceSegmentCardProps) {
  const AddEditButtonsUI = (
    <>
      <FenceGateList gates={props.fenceSegment.gates} onGateDelete={props.handleGateDelete} />
      <Row>
        <Col className="d-flex justify-content-between">
          <Button onClick={props.handleEditStyle} value={props.fenceSegment.id}>
            Edit Style
          </Button>

          <AddGate fenceSegmentId={props.fenceSegment.id} onDropDownSelection={props.handleAddGate} />
        </Col>
      </Row>
    </>
  );
  let ui;
  const CancelGateDrawingUI = (
    <>
      <Row>
        <Col className="d-flex aligns-items-center justify-content-center">
          <Button color="danger" onClick={props.handleCancelGateAdd} value={props.fenceSegment.id}>
            Cancel Adding A Gate
          </Button>
        </Col>
      </Row>
    </>
  );
  if (
    (props.currentPageMode === EstimatePageCurrentMode.AddDoubleGate ||
      props.currentPageMode === EstimatePageCurrentMode.AddSingleGate) &&
    props.isSelected
  ) {
    ui = CancelGateDrawingUI;
  } else {
    ui = AddEditButtonsUI;
  }

  return (
    <Card className="estimate-card-spacing-bottom">
      <CardHeader>
        <Row className="d-flex">
          <Col className="col-8">
            <span>
              {props.fenceSegment.calculateLinearFeet()} LFT {props.fenceSegment.material.label},{" "}
              {props.fenceSegment.style.label}, {props.fenceSegment.color.label},{" Height "}
              {props.fenceSegment.height.label} FT
            </span>
          </Col>
          <Col>
            <Button
              className="float-end"
              color="danger"
              size="sm"
              outline
              onClick={props.handleDeleteStyle}
              value={props.fenceSegment.id}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <CardTitle></CardTitle>
        <CardText></CardText>

        {ui}
      </CardBody>
    </Card>
  );
}

type AddGateProps = {
  onDropDownSelection(fenceSegmentId: string, size: GateSizeEnum): React.MouseEventHandler<HTMLElement>;
  fenceSegmentId: string;
};
function AddGate(props: AddGateProps) {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>Add Gate</DropdownToggle>
      <DropdownMenu>
        <DropdownItem key={"single"} onClick={props.onDropDownSelection(props.fenceSegmentId, GateSizeEnum.SINGLE)}>
          Single Width Gate
        </DropdownItem>
        <DropdownItem key={"double"} onClick={props.onDropDownSelection(props.fenceSegmentId, GateSizeEnum.DOUBLE)}>
          Double Width Gate
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

type FenceGateListProps = {
  onGateDelete: (e: React.MouseEvent<HTMLButtonElement>) => void;
  gates: FenceGate[];
};
function FenceGateList(props: FenceGateListProps) {
  if (props.gates.length === 0) return <></>;

  const formatGateSize = (size: GateSizeEnum) => {
    if (size === GateSizeEnum.DOUBLE) {
      return "Double Width";
    } else {
      return "Singe Width";
    }
  };

  return (
    <>
      <Row>
        <Col>
          <ul>
            {props.gates.map((g) => {
              return (
                <>
                  <li key={g.id} style={{ paddingBottom: "5px" }}>
                    {formatGateSize(g.size)} Gate{" "}
                    <Button color="danger" outline size="sm" value={g.id} onClick={props.onGateDelete}>
                      Delete
                    </Button>
                  </li>
                </>
              );
            })}
          </ul>
        </Col>
      </Row>
    </>
  );
}

export { FenceSegmentCard };
