export class SessionStorageWrapper {
  static addItem<T>(key: string, obj: T) {
    sessionStorage.setItem(key, JSON.stringify(obj));
  }

  static removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  static keyExists(key: string): boolean {
    const strValue = sessionStorage.getItem(key);
    if (strValue === null) return false;

    return true;
  }
  static getItem<T>(key: string): T | undefined {
    const strValue = sessionStorage.getItem(key);
    if (strValue === null) return undefined;

    const obj = JSON.parse(strValue) as T;
    return obj;
  }
}
