import React, { Fragment } from "react";
import { FormGroup, InputProps, Input, Label, InputGroup, InputGroupText } from "reactstrap";
import { FormValue } from "./contracts";

interface withValidationProps {
  formControl: FormValue;
}

const withValidation = <P extends object>(Component: React.ComponentType<P>) =>
  class WithValidation extends React.Component<P & withValidationProps> {
    render() {
      const props = { placeholder: this.props.formControl.placeholder, ...this.props };
      let formControl = "form-control";
      let formGroup = "";
      const showError = props.formControl.touched && !props.formControl.isValid;

      if (showError) {
        formControl = "form-control form-control-danger";
        formGroup = "form-group has-danger";
      }
      //if ((this.props as SelectProps).classNamePrefix === "select") {
      //  formControl = "";
      //}

      return (
        <>
          <FormGroup className={formGroup}>
            <Component className={formControl} {...(props as P)} />
            {showError && props.formControl.validationError && (
              <Label className="error">{props.formControl.validationError}</Label>
            )}
          </FormGroup>
        </>
      );
    }
  };

type CheckBoxProps = InputProps &
  withValidationProps & {
    label: string | JSX.Element;
  };
const _CheckBox = function (props: CheckBoxProps) {
  const { label, formControl, ...elementProps } = props;
  return (
    <>
      <div className="form-check">
        <Label className="form-check-label">
          <Input type="checkbox" value={formControl.value} {...elementProps} />

          <span className="form-check-sign">
            <span className="check" />
          </span>
          {props.label}
        </Label>
      </div>
    </>
  );
};
type RadioButtonProps = InputProps & withValidationProps & {};
const _RadionButtons = function (props: RadioButtonProps) {
  const { formControl, ...elementProps } = props;
  const ui = (formControl.options || []).map((o, i) => {
    const checked = o.value === formControl.value ? true : false;
    return (
      <Fragment key={i}>
        <FormGroup check>
          <Input type="radio" value={o.value} {...elementProps} checked={checked}></Input>{" "}
          <Label style={{ paddingLeft: "5px" }}>{o.label}</Label>
        </FormGroup>
      </Fragment>
    );
  });
  return <div>{ui}</div>;
};
type TextBox = InputProps &
  withValidationProps & {
    iconName?: string;
  };
const _TextInput = function (props: TextBox) {
  const { iconName, formControl, ...elementProps } = props;
  const iconCss = `nc-icon ${iconName}`;
  const showError = formControl.touched && !formControl.isValid;
  const errorCss = showError ? "has-danger" : "s";

  const textWithIcon = (
    <>
      <InputGroup className={errorCss}>
        <InputGroupText>
          <i className={iconCss} />
        </InputGroupText>
        <Input value={formControl.value} {...elementProps} />
      </InputGroup>
    </>
  );

  const textWithoutIcon = <Input value={formControl.value} {...elementProps} />;

  return iconName ? textWithIcon : textWithoutIcon;
};

type BoldLabelProps = {
  children: string;
};
const _BoldLabel = function (props: BoldLabelProps) {
  return (
    <>
      <Label>
        <b>{props.children}</b>
      </Label>
    </>
  );
};

//type DropDownProps = SelectProps & withValidationProps;
//const _DrowDown = function (props: DropDownProps) {
// const { formControl, ...elementProps } = props;
// return <Select {...elementProps} options={formControl.options} value={formControl.value} />;
//};

export const RequiredLabel = _BoldLabel;
export const TextInput = withValidation<TextBox>(_TextInput);
//export const DropDown = withValidation<DropDownProps>(_DrowDown);
export const CheckBox = withValidation<CheckBoxProps>(_CheckBox);
export const RadioButtons = withValidation<RadioButtonProps>(_RadionButtons);
