import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { ToastContainer, toast } from "react-toastify";
import { Row, Col, CardBody, Card, Button } from "reactstrap";

import { SessionStorageWrapper } from "models/session-storage-wapper";
import { selectedLocationKey } from "models/session-storage-keys";
import GoogleMapWrapper from "components/GoogleMapWrapper";
import { Address } from "models/address";
import { GoogleDrawingManagerWrapper } from "components/GoogleDrawingManagerWrapper";
import EstimateSidebar from "./components/EstimateSidebar";
import { FenceSegment, removeGateFromSegments } from "models/fenceSegment";
import { FenceOutline } from "./components/FenceOutline";
import SelectFenceStyleModal from "./components/SelectStyleModal";
import { FenceSegmentAttribute } from "models/fenceAttribute";
import { ensure } from "utils";
import { FenceGate, GateSizeEnum } from "models/fenceGate";
import { GateMarkers } from "./components/GateMarker";
import { EstimateTotal } from "./components/EstimateTotal";
import { ContactInformation } from "models/contact";
import { EstimateAction, EstimateApi } from "api/api";

import "react-toastify/dist/ReactToastify.css";
import { HelpText } from "./components/HelpText";
import { debug } from "console";

export enum EstimatePageCurrentMode {
  None = "None",
  DrawingFence = "Draw",
  EditStyle = "Edit",
  AddSingleGate = "AddSingleGate",
  AddDoubleGate = "AddDoubleGate",
}

export default function EstimatePage() {
  const navigate = useNavigate();
  const { portalId, estimateId } = useParams();
  /* const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY || "",
  });
*/
  const isLoaded = true;

  const mapRef = React.useRef<google.maps.Map>();
  const drawingManagerRef = React.useRef<google.maps.drawing.DrawingManager>();

  const [fenceSegments, setFenceSegments] = React.useState<Array<FenceSegment>>([]);
  const [selectedFenceId, setSelectedFenceId] = React.useState("");
  const [currentPageMode, setPageMode] = React.useState<EstimatePageCurrentMode>(EstimatePageCurrentMode.None);
  const [selectedAddress, setSelectedAddress] = React.useState<Address>();
  const [contactInfo, setContactInfo] = React.useState<ContactInformation>();
  //const [estimateId, setEstimateId] = React.useState<string>();
  const [centerCoords, setCenterCoords] = React.useState<{ lat: number; lon: number }>();

  React.useEffect(() => {
    if (!SessionStorageWrapper.keyExists(selectedLocationKey)) {
      navigate(`/${portalId}/designer`);
    } else {
      const selectedAddress = SessionStorageWrapper.getItem<Address>(selectedLocationKey);
      if (selectedAddress === undefined) navigate("/");

      setCenterCoords({ lat: selectedAddress!.lat, lon: selectedAddress!.lon });
      setSelectedAddress(selectedAddress);
    }
  }, [setCenterCoords, setSelectedAddress, navigate, portalId]);

  /*React.useEffect(() => {
    const selectedAddress = SessionStorageWrapper.getItem<Address>(selectedLocationKey);
    EstimateApi.createEstimate(selectedAddress)
      .then((r) => {
        setEstimateId(r.estimateId);
      })
      .then(() => {
        //error
      });
  }, [setEstimateId]);
*/
  const handlePolylineComplete = (poly: google.maps.Polyline) => {
    const path = poly.getPath();
    const newFenceSegment = new FenceSegment();
    path.forEach((element) => {
      newFenceSegment.addCoordinates(element.lat(), element.lng());
    });

    fenceSegments.push(newFenceSegment);
    poly.setMap(null); // clears from maps
    setFenceSegments(fenceSegments);
    setPageMode(EstimatePageCurrentMode.None); //disable drawing mode
  };

  const handleMobilePolylineDraw = (coords: google.maps.LatLng[]) => {
    const newFenceSegment = new FenceSegment();
    coords.forEach((element) => {
      newFenceSegment.addCoordinates(element.lat(), element.lng());
    });

    fenceSegments.push(newFenceSegment);

    setFenceSegments(fenceSegments);
    setPageMode(EstimatePageCurrentMode.None); //disable drawing mode
  };

  const handleOnLoadDrawingManager = (drawingManager: google.maps.drawing.DrawingManager) => {
    drawingManagerRef.current = drawingManager;
  };

  //#region Sidebar handlers
  const handleAddFenceBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setPageMode(EstimatePageCurrentMode.DrawingFence);
    const newCenter = mapRef.current?.getCenter();
    if (newCenter !== undefined) setCenterCoords({ lat: newCenter.lat(), lon: newCenter.lng() });
  };
  const handleEditStyleBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setPageMode(EstimatePageCurrentMode.EditStyle);
    setSelectedFenceId(e.currentTarget.value);
  };
  const handleDeleteStyleBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const filteredSegments = fenceSegments.filter((x) => x.id !== e.currentTarget.value);
    EstimateApi.saveEstimate(estimateId!, filteredSegments, EstimateAction.RemoveSegment).then((r) => {
      setFenceSegments(filteredSegments);
    });
  };
  const handleCancelDrawingBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setPageMode(EstimatePageCurrentMode.None);
  };
  const handleAddGateDropDownSelect =
    (fenceSegmentId: string, size: GateSizeEnum) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setSelectedFenceId(fenceSegmentId);
      if (size === GateSizeEnum.SINGLE) {
        setPageMode(EstimatePageCurrentMode.AddSingleGate);
      } else if (size === GateSizeEnum.DOUBLE) {
        setPageMode(EstimatePageCurrentMode.AddDoubleGate);
      }
    };

  const hadleDeleteGateBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    removeGateFromSegments(fenceSegments, e.currentTarget.value);
    EstimateApi.saveEstimate(estimateId!, fenceSegments, EstimateAction.RemoveGate).then((r) => {
      setFenceSegments([...fenceSegments]);
    });
  };
  //#endregion

  const handleOnFenceOutlineClick = (fenceId: string) => (e: google.maps.PolyMouseEvent) => {
    e.stop();
    if (selectedFenceId !== fenceId) return;

    const selectedFence = ensure(fenceSegments.find((x) => x.id === fenceId));
    const newGate = new FenceGate();
    newGate.coords = {
      lat: e.latLng?.lat() || 0,
      lon: e.latLng?.lng() || 0,
    };

    if (currentPageMode === EstimatePageCurrentMode.AddSingleGate) {
      newGate.size = GateSizeEnum.SINGLE;
      selectedFence.gates.push(newGate);
      setFenceSegments([...fenceSegments]);
      EstimateApi.saveEstimate(estimateId!, fenceSegments, EstimateAction.AddGate);
    } else if (currentPageMode === EstimatePageCurrentMode.AddDoubleGate) {
      newGate.size = GateSizeEnum.DOUBLE;
      selectedFence.gates.push(newGate);
      setFenceSegments([...fenceSegments]);
      EstimateApi.saveEstimate(estimateId!, fenceSegments, EstimateAction.AddGate);
    }
    setSelectedFenceId(fenceId);
    setPageMode(EstimatePageCurrentMode.None);
  };

  const handleFenceSegmentAttributeSelect = (attributes: FenceSegmentAttribute[]) => {
    const indx = ensure(fenceSegments.findIndex((x) => x.id === selectedFenceId));
    attributes.forEach((atr) => {
      atr.setOnSegment(fenceSegments[indx]);
    });
    //console.info(fenceSegments);
    EstimateApi.saveEstimate(estimateId!, fenceSegments, EstimateAction.AddSegment).then((r) => {
      setFenceSegments([...fenceSegments]);
      setPageMode(EstimatePageCurrentMode.None);
    });
  };

  const handleContactInfoProvided = (c: ContactInformation) => {
    EstimateApi.saveContactInformation(estimateId!, c)
      .then((r) => {
        setContactInfo(c);
        //setEstimateId(r.estimateId);
      })
      .catch(() => {
        toast.error("Error saving contact info.");
      });
  };

  const handleGetFullQuote = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    EstimateApi.saveEstimate(estimateId!, fenceSegments, EstimateAction.ReguestQuote).then((r) => {
      SessionStorageWrapper.removeItem(selectedLocationKey);
      navigate(`/${portalId!}/thank-you/${estimateId}`);
    });
  };

  //#region utils
  const IsPhoneScreenSize = (): boolean => {
    return window.outerWidth < 600;
  };
  const IsMapDrawingMode = (): boolean => {
    return (
      currentPageMode === EstimatePageCurrentMode.AddDoubleGate ||
      currentPageMode === EstimatePageCurrentMode.AddSingleGate ||
      currentPageMode === EstimatePageCurrentMode.DrawingFence
    );
  };
  //#endregion

  return (
    <div>
      <Row xs={1} sm={2}>
        <Col className="bg-light" md={4} hidden={IsPhoneScreenSize() && IsMapDrawingMode()}>
          <Row>
            <Col className="d-flex justify-content-center">
              <h3>Estimate Details</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="estimate-card-spacing-bottom">
                <CardBody>
                  <strong>Worksite</strong>
                  <address>
                    {selectedAddress?.street}
                    <br />
                    {selectedAddress?.city} , {selectedAddress?.state} , {selectedAddress?.zipCode}
                    <br />
                  </address>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <EstimateSidebar
                onEditStyleBtnClick={handleEditStyleBtnClick}
                onDeleteBtnClick={handleDeleteStyleBtnClick}
                onCancelDrawingInProgress={handleCancelDrawingBtnClick}
                onAddGateDropDownSelect={handleAddGateDropDownSelect}
                onGateDeleteBtnClick={hadleDeleteGateBtnClick}
                selectedFenceSegmentId={selectedFenceId}
                fenceSegments={fenceSegments}
                currentMode={currentPageMode}
              ></EstimateSidebar>
            </Col>
          </Row>
          <Row style={{ paddingTop: "10px", paddingBottom: "10px" }} hidden={IsMapDrawingMode()}>
            <Col className="h-30 d-flex align-items-center justify-content-center">
              <Button color="primary" onClick={handleAddFenceBtnClick}>
                Add Fence
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <EstimateTotal
                fences={fenceSegments}
                isContactInformationProvided={contactInfo !== undefined}
                onContactInformationProvided={handleContactInfoProvided}
                onGetFullQuoteClick={handleGetFullQuote}
              />
            </Col>
          </Row>
        </Col>
        <Col className="bg-light" md={8}>
          <div style={{ position: "relative" }}>
            {centerCoords !== undefined && (
              <GoogleMapWrapper
                isLoaded={isLoaded}
                lat={centerCoords!.lat}
                lon={centerCoords!.lon}
                mapRef={mapRef}
                drawingManagerRef={drawingManagerRef}
                onMobilePolylineDraw={handleMobilePolylineDraw}
                isDrawingModeEnabled={IsMapDrawingMode()}
              >
                <FenceOutline
                  selectedFenceId={selectedFenceId}
                  fences={fenceSegments}
                  onClick={handleOnFenceOutlineClick}
                />
                <GateMarkers fences={fenceSegments} />
                {currentPageMode === EstimatePageCurrentMode.DrawingFence ? (
                  <GoogleDrawingManagerWrapper
                    onLoadDrawingManager={handleOnLoadDrawingManager}
                    onPolylineComplete={isMobile ? undefined : handlePolylineComplete} //on handle on not mobile
                  />
                ) : (
                  <></>
                )}
              </GoogleMapWrapper>
            )}
            <HelpText isMobile={isMobile} currentMode={currentPageMode} isInDrawingMode={IsMapDrawingMode()} />
          </div>
        </Col>
      </Row>
      {currentPageMode === EstimatePageCurrentMode.EditStyle && (
        <SelectFenceStyleModal
          onCancelClick={(e) => setPageMode(EstimatePageCurrentMode.None)}
          setSelectedAttributes={handleFenceSegmentAttributeSelect}
          selectedFenceSegment={ensure(fenceSegments.find((x) => x.id === selectedFenceId))}
        />
      )}
      <ToastContainer />
    </div>
  );
}
