import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { createAddressFromPlacesResult } from "models/address";
import { SessionStorageWrapper } from "models/session-storage-wapper";
import { selectedLocationKey } from "models/session-storage-keys";
import { EstimateApi } from "api/api";

export default function SelectLocationPage() {
  const navigate = useNavigate();
  const { portalId } = useParams();
  const onPlaceSelect = (place: google.maps.places.PlaceResult) => {
    let selectedAddress = createAddressFromPlacesResult(place);

    if (selectedAddress !== undefined) {
      SessionStorageWrapper.addItem(selectedLocationKey, selectedAddress);
      EstimateApi.createEstimate(selectedAddress).then((r) => {
        navigate(`/${portalId}/estimate/${r.estimateId}`);
      });
    }
  };

  return (
    <div>
      <div className="px-4 py-5 my-5 text-center">
        <h1 className="display-5 fw-bold">A new fence is just a click away</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">Quickly design and customize your dream fence.</p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <Autocomplete
              placeholder="Select an address to get started"
              className="form-control rounded"
              aria-describedby="search-addon"
              apiKey={process.env.REACT_APP_GMAPS_API_KEY}
              options={{
                types: ["address"],
              }}
              onPlaceSelected={onPlaceSelect}
              libraries={["places", "drawing"]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
