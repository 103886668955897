import * as React from "react";
import { EstimatePageCurrentMode } from "pages/EstimatePage";

export type HelpTextProps = {
  isMobile: boolean;
  currentMode: EstimatePageCurrentMode;
  isInDrawingMode: boolean;
};

export function HelpText(props: HelpTextProps) {
  if (!props.isInDrawingMode) return <></>;
  if (
    props.currentMode === EstimatePageCurrentMode.AddDoubleGate ||
    props.currentMode === EstimatePageCurrentMode.AddSingleGate
  ) {
    return (
      <div
        className="--bs-card-border-color --bs-card-border-width"
        style={{
          position: "absolute",
          top: "0px",
          zIndex: 99,
          backgroundColor: "white",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <p>
          <strong>Single Click</strong> on the fence line to add a gate.
        </p>
      </div>
    );
  } else if (props.currentMode === EstimatePageCurrentMode.DrawingFence && !props.isMobile) {
    return (
      <div
        className="--bs-card-border-color --bs-card-border-width"
        style={{
          position: "absolute",
          top: "0px",
          zIndex: 99,
          backgroundColor: "white",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <p>
          <strong>Single Click</strong> to start drawing your fence. <strong>Single Click</strong> to pivot fence
          segment. <strong>Double Click</strong> to end drawing fence.{" "}
        </p>
      </div>
    );
  } else {
    return (
      <div
        className="--bs-card-border-color --bs-card-border-width"
        style={{
          position: "absolute",
          top: "0px",
          zIndex: 99,
          backgroundColor: "white",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <p>
          <strong>Drag your finger</strong> over over the maps to draw your fence line.
        </p>
      </div>
    );
  }
}
