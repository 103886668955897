import { FenceSegment } from "./fenceSegment";

export type AttributeNode = {
  id: string;
  name: string;
  imageUrl?: string;
  children?: AttributeNode[];
};

export interface FenceSegmentAttribute {
  id: string;
  name: string;
  setOnSegment(segment: FenceSegment): void;
}

export class FenceMaterialAttribute implements FenceSegmentAttribute {
  id: string;
  name: string;

  setOnSegment(segment: FenceSegment) {
    segment.setMaterial(this.id, this.name);
  }

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class FenceStyleAttribute implements FenceSegmentAttribute {
  id: string;
  name: string;

  setOnSegment(segment: FenceSegment) {
    segment.setStyle(this.id, this.name);
  }

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class FenceColorAttribute implements FenceSegmentAttribute {
  id: string;
  name: string;

  setOnSegment(segment: FenceSegment) {
    segment.setColor(this.id, this.name);
  }

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}
export class FenceHeightAttribute implements FenceSegmentAttribute {
  id: string;
  name: string;

  setOnSegment(segment: FenceSegment) {
    segment.setHeight(this.id, this.name);
  }

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}
