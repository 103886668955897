import * as React from "react";
import { GoogleMap } from "@react-google-maps/api";
import { isMobile } from "react-device-detect";

const containerStyle = {
  width: "100%",
  height: "600px",
  //minHeight: "600px",
};

type Props = {
  lat: number;
  lon: number;
  isLoaded: boolean;
  isDrawingModeEnabled: boolean;
  onMobilePolylineDraw: (coords: google.maps.LatLng[]) => void; // desktop
  mapRef: React.MutableRefObject<google.maps.Map | undefined>;
  drawingManagerRef: React.MutableRefObject<google.maps.drawing.DrawingManager | undefined>;
  children: JSX.Element[];
};

function GoogleMapWrapper(props: Props) {
  const polylineCoords: Array<google.maps.LatLng> = [];
  const [map, setMap] = React.useState<google.maps.Map | null>(null);

  const center = {
    lat: props.lat,
    lng: props.lon,
  };

  const handleOnLoad = React.useCallback(
    function callback(map: google.maps.Map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const bounds = new window.google.maps.LatLngBounds(center);
      map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
      map.fitBounds(bounds);
      props.mapRef.current = map;
      setMap(map);
    },
    [center, props.mapRef]
  );

  const handleOnUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  const gestureHandling = props.isDrawingModeEnabled ? "none" : "auto";

  const handleOnMouseMove = (e: google.maps.MapMouseEvent) => {
    if (isMobile && props.isDrawingModeEnabled) {
      if (e.latLng !== null) {
        //console.log(`${e.latLng.lat()} ${e.latLng.lng()}`);
        polylineCoords.push(e.latLng);
      }
    }
  };
  const handleOnMouseUp = (e: google.maps.MapMouseEvent) => {
    if (isMobile && props.isDrawingModeEnabled) {
      console.log(polylineCoords);
      props.onMobilePolylineDraw!(polylineCoords);
      polylineCoords.length = 0;
    }
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      options={{
        fullscreenControl: false,
        gestureHandling,
        mapTypeControl: false,
        rotateControl: false,
        scaleControl: true,
        streetViewControl: false,
        tilt: 0,
        zoomControl: true,
      }}
      zoom={20}
      onLoad={handleOnLoad}
      onUnmount={handleOnUnmount}
      onMouseMove={isMobile ? handleOnMouseMove : undefined}
      onMouseUp={isMobile ? handleOnMouseUp : undefined}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {props.children}
    </GoogleMap>
  );
}

export default React.memo(GoogleMapWrapper);
//export default GoogleMapWrapper;
