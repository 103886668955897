import * as React from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import {
  AttributeNode,
  FenceColorAttribute,
  FenceHeightAttribute,
  FenceMaterialAttribute,
  FenceSegmentAttribute,
  FenceStyleAttribute,
} from "models/fenceAttribute";
//import { fenceAttributesTree as attrTree } from "data/sampleAttributes";
import { ensure } from "utils";
import { FenceSegment } from "models/fenceSegment";
import { EstimateApi } from "api/api";
import { SessionStorageWrapper } from "models/session-storage-wapper";

type CascadingSelection = {
  isMaterialSelected: boolean;
  isStyleSelected: boolean;
  isColorSelected: boolean;
  isHeightSelected: boolean;
};

enum AttibuteTypesEnum {
  Material,
  Style,
  Color,
  Height,
}

type SelectFenceStyleModalProps = {
  selectedFenceSegment: FenceSegment;
  onCancelClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  setSelectedAttributes: (attributes: FenceSegmentAttribute[]) => void;
};

export default function SelectFenceStyleModal(props: SelectFenceStyleModalProps) {
  const initialMaterial = {
    id: "",
    name: "Select Material",
  };

  const initialStyle = {
    id: "",
    name: "Select Style",
  };
  const initialColor = {
    id: "",
    name: "Select Color",
  };
  const initialHeight = {
    id: "",
    name: "Select Height",
  };

  const [fenceMaterial, setFenceMaterial] = React.useState<AttributeNode>(initialMaterial);
  const [fenceStyle, setFenceStyle] = React.useState<AttributeNode>(initialStyle);
  const [fenceColor, setFenceColor] = React.useState<AttributeNode>(initialColor);
  const [fenceHeight, setFenceHeight] = React.useState<AttributeNode>(initialHeight);
  const [isSelectionComplete, setSelectionComplete] = React.useState(false);
  const [selectorVisibility, setSelectorVisibility] = React.useState<CascadingSelection>({
    isMaterialSelected: true,
    isColorSelected: false,
    isHeightSelected: false,
    isStyleSelected: false,
  });
  const [allAttributes, setAllAttributes] = React.useState<AttributeNode[]>([]);

  //load data
  React.useEffect(() => {
    const catalogId = SessionStorageWrapper.getItem<string>("companyId");
    EstimateApi.getFenceAttributes(catalogId!)
      .then((data) => {
        console.log(data);
        setAllAttributes(data.attributes);

        if (!props.selectedFenceSegment.isIncomplete()) {
          //edit mode
          setSelectorVisibility({
            isMaterialSelected: true,
            isColorSelected: true,
            isHeightSelected: true,
            isStyleSelected: true,
          });
          const fs = props.selectedFenceSegment;
          const material = ensure(data.attributes.find((x) => x.id === fs.material.id));
          const style = ensure(material.children!.find((x) => x.id === fs.style.id));
          const color = ensure(style.children!.find((x) => x.id === fs.color.id));
          const height = ensure(color.children!.find((x) => x.id === fs.height.id));

          setFenceMaterial(material);
          setFenceStyle(style);
          setFenceColor(color);
          setFenceHeight(height);
        } else {
          //new style mode
          //// DO NOTHING
        }
      })
      .catch((err) => {
        toast.error("Could not load fence catalog");
      });

    //setAllAttributes(attrTree);
  }, [setAllAttributes, setFenceMaterial, setFenceColor, setFenceStyle, setFenceHeight, props.selectedFenceSegment]);

  const handleDropDownSelection =
    (attrId: string, atrType: AttibuteTypesEnum) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      let selectedAttribute: CascadingSelection;
      switch (atrType) {
        case AttibuteTypesEnum.Material:
          selectedAttribute = {
            isMaterialSelected: true,
            isColorSelected: false,
            isHeightSelected: false,
            isStyleSelected: true,
          };
          setFenceMaterial(ensure(allAttributes.find((x) => x.id === attrId)));
          setFenceStyle(initialStyle);
          setFenceColor(initialColor);
          setFenceHeight(initialHeight);
          setSelectorVisibility(selectedAttribute);
          setSelectionComplete(false);
          break;
        case AttibuteTypesEnum.Style:
          selectedAttribute = {
            isMaterialSelected: true,
            isColorSelected: true,
            isHeightSelected: false,
            isStyleSelected: true,
          };
          setFenceStyle(ensure(fenceMaterial.children!.find((x) => x.id === attrId)));
          setFenceColor(initialColor);
          setFenceHeight(initialHeight);
          setSelectorVisibility(selectedAttribute);
          setSelectionComplete(false);
          break;
        case AttibuteTypesEnum.Color:
          selectedAttribute = {
            isMaterialSelected: true,
            isColorSelected: true,
            isHeightSelected: true,
            isStyleSelected: true,
          };
          setSelectorVisibility(selectedAttribute);
          setFenceColor(ensure(fenceStyle.children!.find((x) => x.id === attrId)));
          setFenceHeight(initialHeight);
          setSelectionComplete(false);
          break;
        case AttibuteTypesEnum.Height:
          selectedAttribute = {
            isMaterialSelected: true,
            isColorSelected: true,
            isHeightSelected: true,
            isStyleSelected: true,
          };
          setFenceHeight(ensure(fenceColor.children!.find((x) => x.id === attrId)));
          setSelectorVisibility(selectedAttribute);
          setSelectionComplete(true);
          break;
        default:
          break;
      }
    };

  const handleSelectClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.setSelectedAttributes([
      new FenceMaterialAttribute(fenceMaterial.id, fenceMaterial.name),
      new FenceStyleAttribute(fenceStyle.id, fenceStyle.name),
      new FenceColorAttribute(fenceColor.id, fenceColor.name),
      new FenceHeightAttribute(fenceHeight.id, fenceHeight.name),
    ]);
  };
  return (
    <Modal isOpen={true}>
      <ModalHeader>Select your fence attributes</ModalHeader>
      <ModalBody>
        {selectorVisibility.isMaterialSelected && (
          <AttributeSelector
            availableAttributes={allAttributes}
            selectedType={AttibuteTypesEnum.Material}
            selectedAttr={fenceMaterial}
            onDropDownSelection={handleDropDownSelection}
          />
        )}
        {selectorVisibility.isStyleSelected && (
          <AttributeSelector
            availableAttributes={fenceMaterial.children || []}
            selectedType={AttibuteTypesEnum.Style}
            selectedAttr={fenceStyle}
            onDropDownSelection={handleDropDownSelection}
          />
        )}
        {selectorVisibility.isColorSelected && (
          <AttributeSelector
            availableAttributes={fenceStyle.children || []}
            selectedType={AttibuteTypesEnum.Color}
            selectedAttr={fenceColor}
            onDropDownSelection={handleDropDownSelection}
          />
        )}
        {selectorVisibility.isHeightSelected && (
          <AttributeSelector
            availableAttributes={fenceColor.children || []}
            selectedType={AttibuteTypesEnum.Height}
            selectedAttr={fenceHeight}
            onDropDownSelection={handleDropDownSelection}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSelectClick} disabled={!isSelectionComplete}>
          Select Style
        </Button>{" "}
        <Button color="secondary" outline onClick={props.onCancelClick}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

type AttributeSelectorProps = {
  availableAttributes: Array<AttributeNode>;
  selectedAttr: AttributeNode;
  selectedType: AttibuteTypesEnum;
  onDropDownSelection(attrId: string, selectedType: AttibuteTypesEnum): React.MouseEventHandler<HTMLElement>;
};

function AttributeSelector(props: AttributeSelectorProps) {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  // const formatImage = (text: string) => {
  //   return `https://placehold.co/100x100?text=${text}`;
  // };
  return (
    <Row>
      <Col style={{ marginBottom: "10px", marginTop: "10px", height: "100px" }} md={6} sm={6}>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle caret>{props.selectedAttr.name}</DropdownToggle>
          <DropdownMenu>
            {props.availableAttributes.map((attr) => {
              return (
                <DropdownItem key={attr.id} onClick={props.onDropDownSelection(attr.id, props.selectedType)}>
                  {attr.name}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </Col>
      <Col md={6} sm={6} className="justify-content-end d-flex" style={{ marginBottom: "10px", marginTop: "10px" }}>
        {props.selectedAttr.imageUrl && <img src={props.selectedAttr.imageUrl} alt=""></img>}
      </Col>
    </Row>
  );
}
