import { ChangeEvent } from "react";
import { isFieldValid } from "./validators";
import { FormValues, SelectOption } from "./contracts";

export const handleDropDownChange = <Keys extends string>(
  elName: Keys,
  formControls: FormValues<Keys>,
  selected?: SelectOption | SelectOption[] | null
) => {
  return updateState(elName, selected || "", formControls);
};
export const handleFormInputChange = function <T extends HTMLInputElement | HTMLTextAreaElement, Keys extends string>(
  event: ChangeEvent<T>,
  formControls: FormValues<Keys>
) {
  const name: string = event.target.name;
  let value: string | boolean = event.target.value;
  if (event.target.type === "checkbox") {
    value = (event.target as HTMLInputElement).checked;
  }

  return updateState(name as Keys, value, formControls);
};

function updateState<Keys extends string>(
  elName: Keys,
  value: string | boolean | SelectOption | SelectOption[],
  formControls: FormValues<Keys>
) {
  const updatedControls = {
    ...formControls,
  };
  const updatedFormElement = {
    ...updatedControls[elName],
  };
  const validationResult = isFieldValid(elName, value, updatedFormElement.validationRules, false);
  updatedFormElement.value = value;
  updatedFormElement.touched = true;
  updatedFormElement.validationError = validationResult.errorMsg;
  updatedFormElement.isValid = validationResult.isValid;
  updatedControls[elName] = updatedFormElement;
  return updatedControls;
}
