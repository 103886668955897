import { ensure } from "../utils";
import { FenceGate } from "./fenceGate";

type Attribute = {
  id: string | undefined;
  label: string;
};
export class FenceSegment {
  id: string;
  material: Attribute;
  style: Attribute;
  color: Attribute;
  height: Attribute;
  gates: FenceGate[];
  coords: LatLon[];

  addCoordinates(lat: number, lon: number) {
    this.coords.push({
      lat: lat,
      lon: lon,
    });
  }
  calculateLinearFeet(): number {
    let totalMeters = 0;
    this.coords.forEach((e, i) => {
      if (i > 0) {
        const f = new google.maps.LatLng(this.coords[i - 1].lat, this.coords[i - 1].lon);
        const s = new google.maps.LatLng(this.coords[i].lat, this.coords[i].lon);
        totalMeters += google.maps.geometry.spherical.computeDistanceBetween(f, s);
      }
    });
    if (totalMeters === 0) {
      return 0;
    }

    return Math.ceil(totalMeters / 0.3048); // total feet
  }

  setMaterial(id: string, label: string) {
    this.material = { id, label };
  }
  setStyle(id: string, label: string) {
    this.style = { id, label };
  }

  setColor(id: string, label: string) {
    this.color = { id, label };
  }
  setHeight(id: string, label: string) {
    this.height = { id, label };
  }
  isIncomplete(): boolean {
    return this.material.label === "" && this.style.label === "" && this.color.label === "" && this.height.label === "";
  }

  getCoordsAsGMapLatLng(): google.maps.LatLng[] {
    let listOfCoords: google.maps.LatLng[] = [];
    this.coords.forEach((element) => {
      listOfCoords.push(new google.maps.LatLng(element.lat, element.lon));
    });

    return listOfCoords;
  }
  constructor() {
    this.coords = [];
    this.id = Math.random().toString(36).substring(2, 8); //https://www.newline.co/books/beginners-guide-to-typescript/generating-unique-ids
    this.material = { id: undefined, label: "" };
    this.style = { id: undefined, label: "" };
    this.color = { id: undefined, label: "" };
    this.height = { id: undefined, label: "" };
    this.gates = [];
  }
}

export type LatLon = {
  lat: number;
  lon: number;
};

export function removeGateFromSegments(fenceSegments: FenceSegment[], gateId: string) {
  let fid = "";
  fenceSegments.forEach((f) => {
    f.gates.forEach((g) => {
      if (g.id === gateId) {
        fid = f.id;
      }
    });
  });

  const gates = ensure(fenceSegments.find((f) => f.id === fid)).gates.filter((g) => g.id !== gateId);
  ensure(fenceSegments.find((f) => f.id === fid)).gates = gates;
}
