import * as React from "react";
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";

export type FenceSegmentCardIncompleteProps = {
  fenceSegmentId: string;
  handleDeleteStyle: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleAddFenceStyle: (e: React.MouseEvent<HTMLButtonElement>) => void;
  fenceLinearFeet: number;
};

export function FenceSegmentCardIncomplete(props: FenceSegmentCardIncompleteProps) {
  return (
    <Card className="estimate-card-spacing-bottom">
      <CardHeader className="d-flex">
        <span>{props.fenceLinearFeet} LFT</span>
        <Button
          className="ms-auto"
          color="danger"
          size="sm"
          outline
          onClick={props.handleDeleteStyle}
          value={props.fenceSegmentId}
        >
          Delete
        </Button>
      </CardHeader>
      <CardBody>
        <CardTitle></CardTitle>
        <Button onClick={props.handleAddFenceStyle} value={props.fenceSegmentId}>
          Add Style
        </Button>
      </CardBody>
    </Card>
  );
}
