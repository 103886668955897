import * as React from "react";
import { Button, Col, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

import { FormValues } from "components/form/contracts";
import { RequiredLabel, TextInput, RadioButtons } from "components/form/controls";
import { handleFormInputChange } from "components/form/event-handlers";
import { ContactInformation } from "models/contact";
import { haveErrors, validateFormValues } from "components/form/validators";
//import { haveErrors, validateFormValues } from "../components/form/validators";
import "./get-contact-info-modal.css";

type Props = {
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmit: (contactInfo: ContactInformation) => void;
};

type FormKeys = "name" | "contactmethod" | "email" | "phone";

const initialState = {
  name: {
    value: "",
    label: "Name",
    placeholder: "Enter your name",
    isValid: false,
    touched: false,
    validationRules: {
      isRequired: true,
    },
    validationError: "",
  },
  contactmethod: {
    value: "",
    label: "Preferred Method of Contact",
    placeholder: "",
    isValid: false,
    touched: false,
    validationRules: {
      isRequired: true,
    },
    validationError: "",
    options: [
      { label: "Email", value: "email" },
      { label: "Phone", value: "phone" },
    ],
  },
  email: {
    value: "",
    label: "Email",
    placeholder: "Your email",
    isValid: false,
    touched: false,
    validationRules: {
      isRequired: true,
      isEmail: true,
    },
    validationError: "",
  },
  phone: {
    value: "",
    label: "Telephone",
    placeholder: "xxx-xxx-xxxx",
    isValid: false,
    touched: false,
    validationRules: {
      isPhone: true,
    },
    validationError: "",
  },
};

export function GetContactInformationModal(props: Props) {
  const [form, setForm] = React.useState<FormValues<FormKeys>>(initialState);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedForm = handleFormInputChange<HTMLInputElement, FormKeys>(e, form);
    setForm(updatedForm);
  };
  const handleConfirmEstimateSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    console.log(form);
    validateFormValues(form);
    console.log(form);
    if (haveErrors(form)) {
      setForm({ ...form });
      return;
    }
    const contactInfo: ContactInformation = {
      name: form.name.value,
      email: form.email.value,
      phone: form.phone.value,
      preferredMethodOfContact: form.contactmethod.value,
    };

    props.onSubmit(contactInfo);
  };
  return (
    <>
      <Modal isOpen={true}>
        <ModalHeader>
          <span>Confirm Your Estimate</span>
        </ModalHeader>
        <ModalBody>
          <span>
            Please provide the best method for us to contact you to schedule an onsite visit at no extra charge.
          </span>
          <Form>
            <Row>
              <Col md={12}>
                <RequiredLabel>{form.name.label}</RequiredLabel>
                <TextInput type="text" bsSize="lg" name="name" formControl={form.name} onChange={handleFormChange} />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <RequiredLabel>{form.contactmethod.label}</RequiredLabel>
                <RadioButtons name="contactmethod" onChange={handleFormChange} formControl={form.contactmethod} />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <RequiredLabel>{form.email.label}</RequiredLabel>
                <TextInput type="email" bsSize="lg" name="email" formControl={form.email} onChange={handleFormChange} />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <RequiredLabel>{form.phone.label}</RequiredLabel>
                <TextInput type="text" bsSize="lg" name="phone" formControl={form.phone} onChange={handleFormChange} />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className="justify-content-end">
          <Button color="primary" onClick={handleConfirmEstimateSubmit}>
            Confirm
          </Button>
          <Button outline onClick={props.onClose}>
            <span>Cancel</span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

/*
type GetContactInfoModalProps = {};
export default function SelectFenceStyleModal(props: GetContactInfoModalProps) {
  return (
    <Modal isOpen={true} fullscreen>
      <ModalHeader>Modal title</ModalHeader>
      <ModalBody></ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSelectClick} disabled={!isSelectionComplete}>
          Select Style
        </Button>{" "}
        <Button color="secondary" onClick={props.onCancelClick}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}*/
